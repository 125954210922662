import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/LoginEmployee/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const loginEmployeeContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
    }),
    [],
  );

  const loginEmployeeMainBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100vh',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    }),
    [],
  );

  const loginEmployeeTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '32px',
      marginBottom: '64px',
      svg: {
        height: '22px',
        marginBottom: '8px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '140px',
      },
    }),
    [],
  );

  const loginEmployeeTitleTypography: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '20px',
    }),
    [],
  );

  const loginEmployeeSSOBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '28px',
      rowGap: '32px',
      'div:last-child': {
        borderBottom: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '96px',
        flexDirection: 'row',
        'div:nth-of-type(2)': {
          borderLeft: '1px solid #757575',
        },
      },
    }),
    [],
  );

  const switchLoginBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'center',
      a: {
        span: {
          marginRight: '12px',
        },
      },
    }),
    [],
  );

  const privacyBox: SxProps = useMemo(
    (): SxProps => ({
      marginTop: '16px',
      marginBottom: '32px',
      display: 'flex',
      justifyContent: 'center',
      a: {
        span: {
          marginX: '12px',
        },
      },
    }),
    [],
  );

  return {
    loginEmployeeMainBox,
    loginEmployeeTitleBox,
    loginEmployeeSSOBox,
    loginEmployeeContainer,
    switchLoginBox,
    loginEmployeeTitleTypography,
    privacyBox,
  };
};
