import {useCallback, useRef} from 'react';

import FullCalendar from '@fullcalendar/react';
import {Box} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {useCalendar} from '@/hooks/calendar';
import {useToast} from '@/hooks/toast';
import {useIsTablet} from '@/hooks/viewport';
import {useStyle} from '@/organisms/Calendar/style';
import {IProps, ISelectedEvent} from '@/organisms/Calendar/type';

const Calendar = ({
  events,
  fullCalendarProps,
  itemClickHandler,
  bookedSlotIds = [],
  hasDownloadButton = false,
  timezone,
  allowMultipleBookings = false,
}: IProps) => {
  const {fullCalendarBox} = useStyle();

  const {t} = useTranslations();

  const {getConfiguration} = useCalendar(timezone);

  const {showErrorToast} = useToast(); // Get functions for displaying success and error toast

  const isTablet = useIsTablet();
  const configuration = getConfiguration(hasDownloadButton, events, isTablet);

  const eventClickHandler = useCallback(
    (info: ISelectedEvent) => {
      if (!info.event.extendedProps.slot.isDisabled) {
        if (
          (!allowMultipleBookings &&
            bookedSlotIds[0] === info.event.extendedProps.slot.id) ||
          allowMultipleBookings ||
          bookedSlotIds.length === 0
        ) {
          itemClickHandler(Number(info.event.id));
        } else {
          showErrorToast({
            message: t('CALENDAR.ALREADY_BOOKED'),
          });
        }
      }
      return null;
    },
    [allowMultipleBookings, bookedSlotIds, itemClickHandler, showErrorToast, t],
  );

  const calendarRef: any = useRef();

  const getApi = useCallback(() => {
    const {current: calendarDom} = calendarRef;

    return calendarDom ? calendarDom.getApi() : null;
  }, []);

  const changeView = useCallback(
    (view: string) => {
      const API = getApi();

      API && API.changeView(view);
    },
    [getApi],
  );

  const renderCalendarView = useCallback(() => {
    if (isTablet) {
      changeView('timeGridWeek');
    } else {
      changeView('timeGridDay');
    }
  }, [changeView, isTablet]);

  return (
    <Box sx={fullCalendarBox}>
      <FullCalendar
        {...configuration}
        {...fullCalendarProps}
        events={events}
        eventClick={eventClickHandler}
        windowResize={renderCalendarView}
        ref={calendarRef}
      />
    </Box>
  );
};

export default Calendar;
