import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/organisms/LoginSSO/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const loginSSOContainer: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderBottom: '1px solid #757575',
      paddingBottom: '36px',
      [theme.breakpoints.up('md')]: {
        width: 'calc(100%/3);', //TODO: da cambiare una volta che verrà aggiunto il quarto brand
        borderBottom: 0,
      },
    }),
    [],
  );

  const loginSSOLogoBox: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '24px',
      svg: {
        height: '25px',
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '106px',
        svg: {
          height: '35px',
        },
      },
    }),
    [],
  );

  const loginSSOButtonBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        borderRight: 'none !important',
        borderLeft: 'none !important',
      },
    }),
    [],
  );

  const loginSSOButton: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      maxWidth: '354px',
      height: '54px',
      marginBottom: '16px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '40px',
      },
    }),
    [],
  );

  return {
    loginSSOContainer,
    loginSSOLogoBox,
    loginSSOButton,
    loginSSOButtonBox,
  };
};
