import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {UseStyle} from './type';

export const useStyle = (): UseStyle => {
  const bookingGuestInputBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      marginBottom: '40px',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignContent: 'center',
      flexDirection: 'column',
      rowGap: '10px',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        rowGap: '0',
        marginBottom: '15px',
      },
    }),
    [],
  );

  const bookingGuestInputInputBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '30%',
        marginRight: '15px',
      },
    }),
    [],
  );

  const bookingGuestInputContentBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    }),
    [],
  );

  const bookingGuestRemoveButtonBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
    }),
    [],
  );

  return {
    bookingGuestInputBox,
    bookingGuestInputInputBox,
    bookingGuestInputContentBox,
    bookingGuestRemoveButtonBox,
  };
};
