import {useCallback, useMemo} from 'react';
// Import the useTranslations hook for internationalization
import {useLocation} from 'react-router-dom';

// Import the Modal component
import {Box, Typography} from '@mui/material';
// Import the useDate custom hook
import {useTranslations} from '@vidiemme/react-i18n';

// Import functions for deleting and getting booking details
import {Button} from '@/atoms/Button';
// Import Box, Button, and Typography components from Material-UI
import {IconSVG} from '@/atoms/IconSVG';
import {PrintDate} from '@/atoms/PrintDate';
// Import necessary React hooks
import {useToast} from '@/hooks/toast';
// Import the IconSVG component
import {useNavigation} from '@/navigation/useNavigation';
import {LoadingMessage} from '@/organisms/LoadingMessage';
// Import the useLocation hook for accessing URL location
import {useStyle} from '@/pages/InvitationSlotDetail/style';
// Import the useNavigation hook for navigation handling
import {useGetBookingDetail} from '@/store/bookings';
// Import style-related functions
import {Modal} from '@/templates/Modal';

const InvitationSlotDetail = () => {
  const {
    goToPreviousPage,
    goToInvitationEdit,
    goToBookingDelete,
    goToInvitation,
  } = useNavigation(); // Get the function for navigating back
  const {t} = useTranslations(); // Get the translation function
  const {showErrorToast} = useToast(); // Get functions for displaying success and error toasts

  const {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalButtonsBox,
    modalButton,
  } = useStyle(); // Get style-related functions

  const location = useLocation(); // Get the current URL location
  const bookingId: number = useMemo(
    () => location.state?.bookingId,
    [location.state?.bookingId],
  ); // Get the booking ID from the URL location state

  const {
    loading: loadingBookingDetail,
    error: errorBookingDetail,
    response: responseBookingDetail,
  } = useGetBookingDetail(bookingId); // Use the useGetBookingDetail hook to retrieve booking details

  const [date, startHour, endHour, eventId, slotId] = useMemo(() => {
    if (responseBookingDetail) {
      const date: string = responseBookingDetail?.slot!.startTime;
      const startHour: string = responseBookingDetail?.slot!.startTime;
      const endHour: string = responseBookingDetail?.slot!.endTime;
      const eventId = responseBookingDetail?.eventId;
      const slotId = responseBookingDetail?.slot!.id;
      return [date, startHour, endHour, eventId, slotId];
    }
    return [];
  }, [responseBookingDetail]); // Use useMemo to calculate date, startHour, and endHour based on appointment data

  const modalButtonEditHandler = useCallback(() => {
    if (responseBookingDetail) {
      goToPreviousPage();
      return setTimeout(
        () =>
          goToInvitationEdit(eventId!, slotId!, bookingId!, {
            state: {
              appointment: responseBookingDetail,
              date,
              startHour,
              endHour,
            },
          }),
        10,
      );
    }
    return null;
  }, [
    bookingId,
    date,
    endHour,
    eventId,
    goToInvitationEdit,
    goToPreviousPage,
    responseBookingDetail,
    slotId,
    startHour,
  ]);

  const modalButtonDeleteHandler = useCallback(() => {
    if (responseBookingDetail) {
      goToBookingDelete(bookingId, {
        state: {
          appointment: responseBookingDetail,
          date,
          startHour,
          endHour,
          invitationInfo: {
            eventId,
            slotId,
            bookingId,
          },
        },
      });
    }
  }, [
    bookingId,
    date,
    endHour,
    eventId,
    goToBookingDelete,
    responseBookingDetail,
    slotId,
    startHour,
  ]);

  const modalContent = useMemo(() => {
    if (errorBookingDetail) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }

    if (responseBookingDetail) {
      const timezone =
        responseBookingDetail.slot!.eventDay.event.location?.timezone;
      const modalTitle = responseBookingDetail.slot!.eventDay.event.name;
      const modalEventNote = responseBookingDetail.note || '-';
      const eventDate = responseBookingDetail.slot!.eventDay.date;

      return (
        <Modal
          backdropVisible={false}
          closeModalHandler={() => goToInvitation(eventId!)}>
          <Box sx={modalContainerBox}>
            <Box sx={modalEventBox}>
              <Typography variant={'titleVersace2'}>{modalTitle}</Typography>
              <PrintDate
                dateProps={{
                  date: eventDate,
                  startHour: responseBookingDetail.slot!.startTime,
                  endHour: responseBookingDetail.slot!.endTime,
                  timezoneLocation: timezone,
                }}
                variant={'bodyRegular'}
              />
            </Box>
            <Box sx={modalNoteBox}>
              <Box sx={modalNoteTitleBox}>
                <IconSVG icon={'note'} size={20} />
                <Typography variant={'bodyBoldSmall'}>
                  {t('EVENT_MODAL.NOTE')}
                </Typography>
              </Box>
              <Typography variant={'bodyRegularSmall'}>
                {modalEventNote}
              </Typography>
            </Box>
            <Box sx={modalButtonsBox}>
              <Button
                variant={'primary'}
                color={'default'}
                onClick={modalButtonEditHandler}
                sx={modalButton}>
                {t('EVENT_MODAL.EDIT')}
              </Button>
              <Button
                variant={'secondary'}
                color={'default'}
                onClick={modalButtonDeleteHandler}
                disabled={loadingBookingDetail}
                loading={loadingBookingDetail}
                sx={modalButton}>
                {t('EVENT_MODAL.DELETE')}
              </Button>
            </Box>
          </Box>
        </Modal>
      );
    }

    if (loadingBookingDetail) {
      return <LoadingMessage />;
    }

    return null;
  }, [
    errorBookingDetail,
    responseBookingDetail,
    loadingBookingDetail,
    showErrorToast,
    t,
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalButtonsBox,
    modalButtonEditHandler,
    modalButton,
    modalButtonDeleteHandler,
    goToInvitation,
    eventId,
  ]);

  return modalContent; // Return the modal content
};

export default InvitationSlotDetail;
