import {useMemo, useCallback} from 'react';

import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {BookingGuest} from '@/store/bookings';

import BookingGuestComponent from '../../molecules/BookingGuestComponent/BookingGuestComponent';
import {useStyle} from './style';
import {IProps} from './type';

const BookingWithGuests = ({
  guests,
  setGuests,
  setValidateError,
  slotLimit,
  bookedSpots,
  initialGuestsCount = -1,
}: IProps) => {
  const {t} = useTranslations();

  const {bookingWithGuestBox, bookingWithGuestDescriptionBox} = useStyle();

  // Add new empty guest form
  const addNewGuestHandler = useCallback(() => {
    setGuests((prevState: BookingGuest[]) => [
      ...prevState,
      {
        firstName: '',
        lastName: '',
      },
    ]);
  }, [setGuests]);

  // Update guests state if the user edit some guest info
  const changeGuestInfoHandler = useCallback(
    (newGuest: BookingGuest, index: number) => {
      const tempGuestList = [...guests];
      tempGuestList[index] = newGuest;
      setGuests(tempGuestList);
    },
    [guests, setGuests],
  );

  // Remove guest from guests state and UI
  const removeGuestHandler = useCallback(
    (index: number) => {
      const tempGuestList = [...guests];
      tempGuestList.splice(index, 1);
      setGuests(tempGuestList);
      if (tempGuestList.length === 0) {
        setValidateError(false);
      }
    },
    [guests, setGuests, setValidateError],
  );
  // Max limit for guests
  // if it's a new booking, the value of initialGuestsCount is -1 to count the booking owner in guests limit calculation
  const maxGuests = useMemo(() => {
    return slotLimit - bookedSpots + initialGuestsCount;
  }, [slotLimit, bookedSpots, initialGuestsCount]);

  const isDisabled = useMemo(() => {
    return guests.length >= maxGuests;
  }, [guests, maxGuests]);

  return (
    <Box sx={bookingWithGuestBox}>
      <Box sx={bookingWithGuestDescriptionBox}>
        <Typography variant={'bodyRegular'}>
          {t('BOOKING_MODAL.GUESTS.DESCRIPTION', {maxGuests})}
        </Typography>
      </Box>
      {guests.map((guest: BookingGuest, index: number) => (
        <BookingGuestComponent
          key={`guest-${index}`}
          guest={guest}
          index={index}
          setErrorHandler={setValidateError}
          changeGuestInfoHandler={newGuest =>
            changeGuestInfoHandler(newGuest, index)
          }
          removeGuestHandler={removeGuestHandler}
        />
      ))}
      {/* Add New Guest Button */}
      <Button
        variant="primary"
        color="default"
        onClick={addNewGuestHandler}
        disabled={isDisabled}>
        {t('BOOKING_MODAL.GUESTS.ADD_BUTTON')}
      </Button>
    </Box>
  );
};

export default BookingWithGuests;
