import {useCallback} from 'react';
import {useParams} from 'react-router-dom';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {Box, Typography} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';

import {Button} from '@/atoms/Button';
import {IconSVG} from '@/atoms/IconSVG';
import {PrintDate} from '@/atoms/PrintDate';
import {useUserContext} from '@/contexts/user';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {ROLES} from '@/store/roles';
import {useGetSlot} from '@/store/slots/hooks';
import {Modal} from '@/templates/Modal';

import {useStyle} from './style';

const EventSlotDetail = () => {
  const {
    goToPreviousPage,
    goToEventBookedPeople,
    goToEventDoorman,
    goToEventBehalfOfBooking,
    goToEventSlotDelete,
    goToEventSlotDisable,
  } = useNavigation();

  const {t} = useTranslations();
  const {showErrorToast} = useToast();

  const {role} = useUserContext();

  const {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalButtonsBoxContainer,
    modalButtonsBox,
    modalButton,
    modalDeleteSlotButtonBox,
    modalDeleteSlotButton,
  } = useStyle();

  const {id, slotId} = useParams();

  const {loading, error, response} = useGetSlot(Number(slotId));

  const handleShowBookedPeopleDetails = useCallback(() => {
    goToEventBookedPeople(parseInt(id!), parseInt(slotId!));
  }, [goToEventBookedPeople, id, slotId]);

  const handleDoorman = useCallback(() => {
    goToEventDoorman(parseInt(id!), parseInt(slotId!));
  }, [goToEventDoorman, id, slotId]);

  const handleBehalfOfBooking = useCallback(() => {
    goToEventBehalfOfBooking(parseInt(id!), parseInt(slotId!));
  }, [goToEventBehalfOfBooking, id, slotId]);

  const handleSlotDelete = useCallback(() => {
    if (response) {
      goToEventSlotDelete(parseInt(id!), parseInt(slotId!), {
        state: {bookedSpots: response.bookedSpots || 0},
      });
    }
  }, [goToEventSlotDelete, response, id, slotId]);

  const handleSlotDisable = useCallback(() => {
    if (response) {
      goToEventSlotDisable(parseInt(id!), parseInt(slotId!), {
        state: {isEnabled: !!response.slotLimit},
      });
    }
  }, [goToEventSlotDisable, id, response, slotId]);

  if (loading) {
    return null;
  }

  if (error) {
    showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    return null;
  }

  if (response) {
    const {
      eventDay: {
        date,
        event: {name, location},
      },
      startTime,
      endTime,
      bookedSpots,
      slotLimit,
    } = response;

    const modalTitle = name;

    return (
      <Modal backdropVisible={false} closeModalHandler={goToPreviousPage}>
        <Box sx={modalContainerBox}>
          <Box sx={modalEventBox}>
            <Typography variant={'titleVersace2'}>{modalTitle}</Typography>
            <PrintDate
              dateProps={{
                date,
                startHour: startTime,
                endHour: endTime,
                timezoneLocation: location.timezone,
              }}
              variant={'bodyRegular'}
            />
          </Box>
          <Box sx={modalNoteBox}>
            <Box sx={modalNoteTitleBox}>
              <IconSVG icon="user" size={20} />
              <Typography variant={'bodyBoldSmall'}>
                {t('EVENT_SLOT_DETAIL.PEOPLE_BOOKED', {
                  bookedSpots,
                  slotLimit,
                })}
              </Typography>
            </Box>
          </Box>
          <Box sx={modalButtonsBoxContainer}>
            <Box sx={modalButtonsBox}>
              <Button
                variant={'primary'}
                color={'default'}
                onClick={handleShowBookedPeopleDetails}
                sx={modalButton}>
                {t('EVENT_SLOT_DETAIL.VIEW_BOOKED_PEOPLE')}
              </Button>
            </Box>
            <Box sx={modalButtonsBox}>
              <Button
                variant={'primary'}
                color={'default'}
                onClick={handleDoorman}
                sx={modalButton}>
                {t('EVENT_SLOT_DETAIL.DOORMAN')}
              </Button>
            </Box>
            {(role === ROLES.ADMIN || role === ROLES.OPERATOR) && (
              <Box sx={modalButtonsBox}>
                <Button
                  variant={'primary'}
                  color={'default'}
                  onClick={handleBehalfOfBooking}
                  sx={modalButton}>
                  {t('EVENT_SLOT_DETAIL.BEHALF_OF_BOOKING')}
                </Button>
              </Box>
            )}
            {role === ROLES.ADMIN && (
              <Box sx={modalButtonsBox}>
                <Button
                  variant={'primary'}
                  color={'default'}
                  onClick={handleSlotDisable}
                  sx={modalButton}>
                  {!!slotLimit
                    ? t('EVENT_SLOT_DETAIL.DISABLE_SLOT')
                    : t('EVENT_SLOT_DETAIL.ENABLE_SLOT')}
                </Button>
              </Box>
            )}
          </Box>
          {role === ROLES.ADMIN && (
            <Box sx={modalDeleteSlotButtonBox}>
              <Button
                sx={modalDeleteSlotButton}
                variant={'secondary'}
                color={'default'}
                onClick={handleSlotDelete}>
                <DeleteOutlineIcon />
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    );
  }
  return null;
};

export default EventSlotDetail;
