import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {Box, TextField} from '@mui/material';
import {useTranslations} from '@vidiemme/react-i18n';
import validate from 'validate.js';

import {Button} from '@/atoms/Button';
import {BookingGuest} from '@/store/bookings';

import {useStyle} from './style';
import {IProps} from './type';

const BookingGuestComponent = ({
  guest,
  changeGuestInfoHandler,
  index,
  setErrorHandler,
  removeGuestHandler,
}: IProps) => {
  // Styles
  const {
    bookingGuestInputContentBox,
    bookingGuestInputBox,
    bookingGuestInputInputBox,
    bookingGuestRemoveButtonBox,
  } = useStyle();

  // Translation
  const {t} = useTranslations();

  // State for Guest Name
  const [guestName, setGuestName] = useState(guest.firstName || '');

  // State for Guest Last Name
  const [guestLastName, setGuestLastName] = useState(guest.lastName || '');

  // Current Guest Object
  const currentGuest: BookingGuest = useMemo(
    () => ({
      firstName: guestName,
      lastName: guestLastName,
    }),
    [guestLastName, guestName],
  );

  // Validate the guest data and set errors
  useEffect(() => {
    setErrorHandler(
      validate(
        {
          name: currentGuest.firstName,
          lastName: currentGuest.lastName,
        },
        {
          name: {
            length: {minimum: 2, message: ''},
          },
          lastName: {
            length: {minimum: 2, message: ''},
          },
        },
      ),
    );
  }, [currentGuest.firstName, currentGuest.lastName, setErrorHandler]);

  // Callback to change guest information
  const changeGuestInfoHandlerCallback = useCallback(() => {
    return changeGuestInfoHandler(currentGuest, index);
  }, [changeGuestInfoHandler, currentGuest, index]);

  // Handler for changing guest name
  const guestNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGuestName(event.target.value);
    },
    [],
  );

  // Handler for changing guest last name
  const guestLastNameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setGuestLastName(event.target.value);
    },
    [],
  );

  return (
    <Box sx={bookingGuestInputContentBox}>
      <Box sx={bookingGuestInputBox}>
        {/* Input field for guest first name */}
        <TextField
          onChange={guestNameChangeHandler}
          onBlur={changeGuestInfoHandlerCallback}
          label={`${t('INVITE_GUEST.FIRST_NAME')}*`}
          type="text"
          sx={bookingGuestInputInputBox}
          value={guestName}
        />
        {/* Input field for guest last name */}
        <TextField
          onChange={guestLastNameChangeHandler}
          onBlur={changeGuestInfoHandlerCallback}
          label={`${t('INVITE_GUEST.LAST_NAME')}*`}
          type="text"
          sx={bookingGuestInputInputBox}
          value={guestLastName}
        />

        <Box sx={bookingGuestRemoveButtonBox}>
          <Button
            variant={'secondary'}
            color={'default'}
            onClick={() => removeGuestHandler(index)}>
            {t('BOOKING_MODAL.GUESTS.REMOVE_BUTTON')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default BookingGuestComponent;
