import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import {useTranslations} from '@vidiemme/react-i18n';

import {IconSVG} from '@/atoms/IconSVG';
import {PrintDate} from '@/atoms/PrintDate';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {BookingWithGuests} from '@/organisms/BookingWithGuests';
import {useStyle} from '@/pages/InvitationSlotBooking/style';
import {BookingGuest} from '@/store/bookings';
import {useSetBooking} from '@/store/bookings';
import {Category} from '@/store/categories';
import {INPUT_PROPS, CHARACTER_LIMIT} from '@/store/inputProps';
import {Presence} from '@/store/presence';
import {useGetSlot} from '@/store/slots/hooks';
import {SideModal} from '@/templates/SideModal';

const InvitationSlotBooking = () => {
  const {
    bookingModalContainerBox,
    bookingModalEventTitle,
    bookingModalDetailsBox,
    bookingModalDetailsLocationBox,
    bookingModalDetailsLocation,
    bookingModalDetailsLocationTitle,
    bookingModalDetailsLocationRemote,
    bookingModalDetailsLocationRemoteTitle,
    bookingModalDetailsEventBox,
    bookingModalDetailsCorporateEventRadioGroup,
    bookingModalDetailsCorporateTextField,
    bookingModalDetailsCorporateEventRadio,
  } = useStyle();

  const {goToPreviousPage} = useNavigation();
  const {t} = useTranslations();
  const {showErrorToast, showSuccessToast} = useToast();

  const {slotId, id: eventId} = useParams();

  const [presenceTypeValue, setPresenceTypeValue] = useState<Presence>(
    Presence.PERSON,
  );

  const [noteValue, setNoteValue] = useState('');

  const presenceHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPresenceTypeValue((event.target as HTMLInputElement).value as Presence);
  };

  const noteHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNoteValue((event.target as HTMLInputElement).value);
  };

  const {
    loading: loadingSlotDetail,
    error: errorSlotDetail,
    response: responseSlotDetail,
  } = useGetSlot(Number(slotId));

  const [guests, setGuests] = useState<BookingGuest[]>([]); // Booking guests State

  const [validateError, setValidateError] = useState(false);

  const eventObj = useMemo(() => {
    if (responseSlotDetail) {
      const startDate: string = responseSlotDetail.startTime;
      const endDate: string = responseSlotDetail.endTime;
      const {
        name,
        location,
        event_type: eventType,
        category,
      } = responseSlotDetail.eventDay.event;

      return {
        eventTimezone: location.timezone,
        eventTitle: name,
        eventDate: startDate,
        eventStartHour: startDate,
        eventEndHour: endDate,
        eventLocationTitle: location.name,
        eventLocationDescription: location.full_address,
        isRemote: eventType === Presence.REMOTE,
        category: category?.name,
      };
    }

    return {};
  }, [responseSlotDetail]);

  const bookingRequest = useMemo(
    () => ({
      eventId: parseInt(eventId!),
      slotId: parseInt(slotId!),
      note: noteValue,
      presenceType: presenceTypeValue as Presence,
      ...(eventObj.category === Category.ORDINARY_OPENING ? {guests} : {}),
    }),
    [eventId, noteValue, presenceTypeValue, slotId, eventObj.category, guests],
  );

  const {
    loading: loadingBookingRequest,
    error: errorBookingRequest,
    response: responseBookingRequest,
    run: runBookingRequest,
  } = useSetBooking(bookingRequest);

  const isLoading = loadingSlotDetail || loadingBookingRequest;

  useEffect(() => {
    if (errorSlotDetail) {
      showErrorToast({message: t('RESPONSE_ERROR_MESSAGE.MESSAGE')});
    }
  }, [errorSlotDetail, showErrorToast, t]);

  useEffect(() => {
    if (errorBookingRequest) {
      showErrorToast({message: t('BOOKING_MODAL.ERROR')});
    } else if (responseBookingRequest) {
      showSuccessToast({message: t('BOOKING_MODAL.SUCCESS')});
      goToPreviousPage();
    }
  }, [
    errorBookingRequest,
    goToPreviousPage,
    responseBookingRequest,
    showErrorToast,
    showSuccessToast,
    t,
  ]);

  return (
    <SideModal
      title={t('BOOKING_MODAL.TITLE')}
      subTitle={t('BOOKING_MODAL.SUB_TITLE')}
      closeHandler={goToPreviousPage}
      textButtonOne={t('BOOKING_MODAL.TEXT_BUTTON_ONE')}
      textButtonTwo={t('BOOKING_MODAL.TEXT_BUTTON_TWO')}
      CTAButtonOne={goToPreviousPage}
      CTAButtonTwo={runBookingRequest!}
      buttonOneDisabled={false}
      buttonTwoDisabled={isLoading || validateError}
      buttonTwoLoading={isLoading}>
      <Box sx={bookingModalContainerBox}>
        <Typography variant={'titleVersace2'} sx={bookingModalEventTitle}>
          {eventObj.eventTitle}
        </Typography>
        <PrintDate
          dateProps={{
            date: eventObj.eventDate!,
            startHour: eventObj.eventStartHour!,
            endHour: eventObj.eventEndHour!,
            timezoneLocation: eventObj.eventTimezone!,
          }}
          variant={'bodyRegular'}
        />
        <Box sx={bookingModalDetailsBox}>
          <Box sx={bookingModalDetailsLocationBox}>
            <Box sx={bookingModalDetailsLocation}>
              <IconSVG icon={'location-pin'} size={15} />
              <Typography
                sx={bookingModalDetailsLocationTitle}
                variant={'bodyBoldSmall'}>
                {eventObj.eventLocationTitle}
              </Typography>
            </Box>
            <Typography variant={'bodyRegularSmall'}>
              {eventObj.eventLocationDescription}
            </Typography>
          </Box>
          {eventObj.isRemote && (
            <Box sx={bookingModalDetailsLocationRemote}>
              <IconSVG icon={'location-pin'} size={15} />
              <Typography
                variant={'bodyBoldSmall'}
                sx={bookingModalDetailsLocationRemoteTitle}>
                {t('BOOKING_MODAL.REMOTE')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {eventObj.category === Category.CORPORATE && (
        <Box sx={bookingModalDetailsEventBox}>
          <FormControl>
            <FormLabel id="access-event">
              <Typography variant={'titleAktive3'}>
                {t('BOOKING_MODAL.ACCESS_EVENT')}
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-labelledby="access-event"
              name="access-event-group"
              value={presenceTypeValue}
              onChange={presenceHandleChange}
              sx={bookingModalDetailsCorporateEventRadioGroup}>
              <FormControlLabel
                value="in_person"
                control={<Radio sx={bookingModalDetailsCorporateEventRadio} />}
                label={
                  <Typography variant={'bodyRegular'}>
                    {t('BOOKING_MODAL.IN_PERSON')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="remote"
                control={<Radio sx={bookingModalDetailsCorporateEventRadio} />}
                label={
                  <Typography variant={'bodyRegular'}>
                    {t('BOOKING_MODAL.REMOTE')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      )}

      {eventObj.category === Category.ORDINARY_OPENING && (
        <BookingWithGuests
          guests={guests}
          setGuests={setGuests}
          setValidateError={setValidateError}
          slotLimit={responseSlotDetail?.slotLimit!}
          bookedSpots={responseSlotDetail?.bookedSpots!}
        />
      )}

      <TextField
        sx={bookingModalDetailsCorporateTextField}
        fullWidth
        label={t('BOOKING_MODAL.MESSAGE')}
        id="fullWidth"
        minRows={6}
        maxRows={6}
        multiline={true}
        value={noteValue}
        disabled={isLoading}
        onChange={noteHandleChange}
        inputProps={INPUT_PROPS}
        helperText={`${noteValue.length}/${CHARACTER_LIMIT}`}
      />
    </SideModal>
  );
};
export default InvitationSlotBooking;
