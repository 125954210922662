export enum AnonymousRoutes {
  LOGIN_EMPLOYEE = '/', // Route for employee login
  LOGIN_EXTERNAL = '/login', // Route for external user login
  PRIVACY_POLICY = '/privacy-policy',
}

export enum AuthRoutes {
  BOOKINGS = '/bookings', // Route for displaying a list of user's appointments
  BOOKING_EDIT = '/bookings/:id', // Route for viewing or editing an appointment
  BOOKING_DELETE = '/bookings/:id/delete', // Route for deleting an appointment

  EVENT = '/events/:id', // Route for viewing or editing event details
  EVENT_SLOT_BOOKING_DELETE = '/events/:id/calendar/:slotId/booked-people/:bookingId/delete', // Route to delete a booking from booked people list
  EVENT_CALENDAR = '/events/:id/calendar', // Route for viewing event calendar
  EVENT_INVITATIONS = '/events/:id/calendar/:slotId/booked-people', // Route for viewing people booked for an event
  EVENT_SLOT_DETAILS = '/events/:id/calendar/:slotId', // Route to show the slot detail in calendar
  EVENT_SLOT_DELETE = '/events/:id/calendar/:slotId/delete', // Route to delete a slot
  EVENT_SLOT_EDIT = '/events/:id/calendar/:slotId/disable', // Route to disable a slot
  EVENT_DOORMAN = '/events/:id/calendar/:slotId/doorman', // Route for operators to check the presence of booked people
  EVENT_BEHALF_OF_BOOKING = '/events/:id/calendar/:slotId/booking', // Route for admin or operator to make a book on behalf of someone else
  EVENT_BOOKINGS_DOWNLOAD = '/events/:id/calendar/booked-people', // Route for download the bookings for a specific range date

  EVENTS = '/events', // Route for managing a list of events
  EVENT_TYPE_TO_CREATE = '/events/create-selections', // Route for choose which type of event create
  EVENT_CREATE = '/events/create', // Route for creating a new event
  EVENT_DELETE = '/events/:id/delete', // Route for deleting an event

  INVITATION_REGISTER = '/invitations/:id/register', // invitation calendar
  INVITATION_SLOT_BOOKING = '/invitations/:id/:slotId/booking',
  INVITATION_GUEST = '/invitations/:id/guest', // Route for viewing invitation calendar
  INVITATION_SLOT_DETAILS = '/invitations/:id/:slotId',
  INVITATION_SLOT_DETAILS_EDIT = '/invitations/:id/:slotId/:bookingId',
  INVITATION_FROM_EMAIL = '/invitations/calendar', //invitations/calendar?event_id={id}
  INVITATION = '/invitations/:id', // Route for viewing invitation calendar
  INVITATIONS = '/invitations', // Route for managing a list of invitations

  USERS = '/users', // Route for managing users
}
