import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const bookingModalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      backgroundColor: '#F4F4F4',
      display: 'flex',
      flexDirection: 'column',
      padding: '18px',
      marginBottom: '32px',
    }),
    [],
  );

  const bookingModalEventTitle: SxProps = useMemo(
    (): SxProps => ({marginBottom: '16px'}),
    [],
  );

  const bookingModalDetailsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      rowGap: '15px',
      marginTop: '16px',
      [theme.breakpoints.up('md')]: {
        rowGap: '0',
        flexDirection: 'row',
      },
    }),
    [],
  );

  const bookingModalDetailsLocationBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        marginRight: '200px',
      },
    }),
    [],
  );

  const bookingModalUserInfoBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      marginBottom: '10px',
      alignContent: 'space-between',
      rowGap: '10px',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    }),
    [],
  );

  const bookingModalUserInfoInputBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 'calc((100% / 3) - 15px)',
      },
    }),
    [],
  );

  const bookingModalDetailsLocation: SxProps = useMemo(
    (): SxProps => ({display: 'flex', alignItems: 'center'}),
    [],
  );

  const bookingModalDetailsLocationTitle: SxProps = useMemo(
    (): SxProps => ({marginLeft: '8px'}),
    [],
  );

  const bookingModalDetailsLocationRemote: SxProps = useMemo(
    (): SxProps => ({display: 'flex', alignItems: 'center'}),
    [],
  );

  const bookingModalDetailsLocationRemoteTitle: SxProps = useMemo(
    (): SxProps => ({marginLeft: '8px'}),
    [],
  );

  const bookingModalDetailsCorporateEventBox: SxProps = useMemo(
    (): SxProps => ({marginBottom: '48px'}),
    [],
  );

  const bookingModalDetailsCorporateEventRadioGroup: SxProps = useMemo(
    (): SxProps => ({display: 'flex', flexDirection: 'row'}),
    [],
  );

  const bookingModalDetailsCorporateEventRadio: SxProps = useMemo(
    (): SxProps => ({
      color: 'black',
      '&.Mui-checked': {
        color: 'black',
      },
    }),
    [],
  );

  const bookingModalDetailsCorporateTextField: SxProps = useMemo(
    (): SxProps => ({
      '& label.Mui-focused': {
        color: theme.palette.gray3,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.gray3,
        },
      },
    }),
    [],
  );

  return {
    bookingModalContainerBox,
    bookingModalEventTitle,
    bookingModalDetailsBox,
    bookingModalUserInfoBox,
    bookingModalUserInfoInputBox,
    bookingModalDetailsLocationBox,
    bookingModalDetailsLocation,
    bookingModalDetailsLocationTitle,
    bookingModalDetailsLocationRemote,
    bookingModalDetailsLocationRemoteTitle,
    bookingModalDetailsCorporateEventBox,
    bookingModalDetailsCorporateEventRadioGroup,
    bookingModalDetailsCorporateTextField,
    bookingModalDetailsCorporateEventRadio,
  };
};
