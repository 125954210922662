import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IPropsStyle} from '@/pages/InvitationSlotDetail/type';
import {theme} from '@/themes/default';

export const useStyle = (): IPropsStyle => {
  const modalContainerBox: SxProps = useMemo(
    (): SxProps => ({
      width: 'calc(100vw - 100px)', // 110 = 20*2 padding + 30*2 boxshadow
      height: '320px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up('md')]: {
        width: '450px',
      },
    }),
    [],
  );

  const modalEventBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const modalNoteBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const modalNoteTitleBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'center',
      svg: {marginRight: '8px'},
    }),
    [],
  );

  const modalButtonsBox: SxProps = useMemo(
    (): SxProps => ({
      display: 'flex',
      alignItems: 'row',
      justifyContent: 'space-between',
    }),
    [],
  );

  const modalButton: SxProps = useMemo(
    (): SxProps => ({
      width: '50%',
    }),
    [],
  );

  return {
    modalContainerBox,
    modalEventBox,
    modalNoteBox,
    modalNoteTitleBox,
    modalButtonsBox,
    modalButton,
  };
};
