import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {theme} from '@/themes/default';

import {IUseStyle} from './interfaces';

export const useStyle = (): IUseStyle => {
  const editBookingDetailsBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      margin: '0 0 40px 0',
      padding: '16px',
      backgroundColor: theme.palette.gray1,
      display: 'flex',
      flexDirection: 'column',
    }),
    [],
  );

  const editBookingDetailsTitle: SxProps = useMemo(
    (): SxProps => ({
      marginBottom: '16px',
    }),
    [],
  );

  const editBookingDropDownBox: SxProps = useMemo(
    (): SxProps => ({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: '20px',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    }),
    [],
  );

  const editBookingTextField: SxProps = useMemo(
    (): SxProps => ({
      '& label.Mui-focused': {
        color: theme.palette.gray3,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.gray3,
        },
      },
    }),
    [],
  );

  return {
    editBookingDetailsBox,
    editBookingDetailsTitle,
    editBookingDropDownBox,
    editBookingTextField,
  };
};
