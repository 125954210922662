import {useMemo} from 'react';

import {SxProps} from '@mui/material';

import {IUseStyle} from './type';

export const useStyle = (): IUseStyle => {
  const bookingWithGuestBox: SxProps = useMemo(
    (): SxProps => ({marginBottom: '48px'}),
    [],
  );

  const bookingWithGuestDescriptionBox: SxProps = useMemo(
    (): SxProps => ({marginBottom: '16px'}),
    [],
  );

  return {
    bookingWithGuestBox,
    bookingWithGuestDescriptionBox,
  };
};
